
import { defineComponent, ref } from "vue";
import { Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import ElInputVeeValidate from "@/components/forms/ElInputVeeValidate.vue";
import SetNewPasswordModal from "@/components/modals/reset-password/SetNewPasswordModal.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "password-reset",
  components: {
    Form,
    ElInputVeeValidate,
    SetNewPasswordModal
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const statusResetPassword = ref(true);
    const statusModal = ref(false);
    const showConfir = ref(true);
    const showPassword = ref(true);

    const submitButton = ref<HTMLElement | null>(null);
    //Create form validation object
    const forgotPassword = Yup.object().shape({
      password: Yup.string().required().min(8).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/, t('signUpPage.errorValidatePassword')),
      confirm_password: Yup.string()
        .required()
        .min(8)
        .oneOf(
          [Yup.ref("password"), null],
          t("signUpPage.formErrorConfirmPassword")
        )
        .label(t("signUpPage.formLabelConfirmPassword")),
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      let payload = {
        password : values.password,
        token : router.currentRoute.value.query.token,
        reset_password : true
      }
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      store
          .dispatch(Actions.FORGOT_PASSWORD, payload)
          .then((res) => {
            statusResetPassword.value = true;
          })
          .catch((err) => {
            statusResetPassword.value = false;
          }).finally(() => {
            statusModal.value = true;
            submitButton.value?.removeAttribute("data-kt-indicator");
          })
    };

    function handleClose(type: string): void {
      if (statusResetPassword.value) {
        router.push("/sign-in");
      } else {
        router.push("/request-password-reset");
      }
    }

    const showPasswordText = (type) => {
      console.log(type);
      if (type == "confirm_password") {
        showConfir.value = !showConfir.value;
      } else {
        showPassword.value = !showPassword.value;
      }
    };

    return {
      onSubmitForgotPassword,
      handleClose,
      forgotPassword,
      submitButton,
      statusResetPassword,
      statusModal,
      showPassword,
      showConfir,
      showPasswordText,
    };
  },
});
